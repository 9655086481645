.landing__section {
  background: white;
  position: sticky;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.animation__wrapper {
  position: relative;
}
.mac__wrapper {
  z-index: 2;
  transform-origin: center 88px;
  // background: radial-gradient(
  //   ellipse at center,
  //   #641544 0%,
  //   rgba(0, 0, 0, 0) 67%
  // );
  width: 1362px;
  height: 898px;
  position: relative;
  left: 50%;
  margin-left: -681px;
  /* margin-top: 0; */
  /* transform: scale(2); */

  .mac__text {
    pointer-events: none;
    position: fixed;
    top: 8rem;
    left: 0;
    flex-direction: column;
    right: 0;
    bottom: 0;
    color: #012345;
    text-align: center;
    z-index: 10;
    height: 50%;
    display: flex;
    justify-content: center;

    h2 {
      font-size: 1rem;
    }
  }
}

.mac__device {
  display: block;
  // max-height: 50%;
  // max-width: 50%;
  width: 20rem;
  height: 20rem;
  // bottom: -10rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 3rem;
  z-index: 10;
  animation: 10s spin linear infinite;
  // z-index: -2;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.macbook__pro__wrapper:before {
  content: "";
  display: block;
  background: url("../mac.png");
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: 1362px;
  height: 898px;
  background-size: 1362px 898px;
  background-repeat: no-repeat;
  position: relative;
  left: 50%;
  margin-left: -681px;
}

.macbook__pro__wrapper .mac__desktop {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 30px;
  width: 882px;
  height: 552px;
  background-size: 882px 552px;
  -webkit-transform: perspective(0);
  transform: perspective(0);
}
.macbook__pro__wrapper .desktop__overlay {
  display: none;
  position: absolute;
  background: black;
  bottom: 0px;
  opacity: 0.5;
  top: 30px;
  width: 882px;
  left: 0;
  right: 0;
  height: 552px;
  margin: 0 auto;
  background-size: 882px 552px;
  -webkit-transform: perspective(0);
  transform: perspective(0);
}

.call__to__action {
  .start__learning__btn {
    cursor: pointer;
    margin-top: 2rem;
    border-radius: 5px;
    background: white;
    color: #012345;
    font-weight: 600;
    border: 1px solid #012345;
    padding: 1rem 2.5rem;
    transition: all ease-in-out var(--transition-speed);
  }

  .start__learning__btn:hover {
    background: #012345;
    border: 1px solid #012345;
    color: white;
  }
}

.nav__wrapper {
  opacity: 1;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  height: 72px;
  left: 0;
  right: 0;
  // max-width: 1024px;
  margin: 1.5rem 2.5rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  * {
    z-index: 999;
  }
}

.nav__wrapper .nav__logo .nav__logo__img {
  height: 100%;
  max-height: 72px;
  transition: 250ms ease-in-out;
}
.nav__wrapper .nav__logo:hover .nav__logo__img {
  transform: rotate(30) scale(1.25);
}

.nav__wrapper * {
  z-index: 4;
}

.nav__wrapper .nav__links .nav__link {
  text-decoration: none;
  color: #012345;
  margin: 0 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.nav__wrapper .nav__links .nav__link.signup__btn {
  border: 1.5px solid #012345;
  border-radius: 2px;
  padding: 0.5rem 1.5rem;
  -webkit-transition: all ease-in-out var(--transition-speed);
  transition: all ease-in-out var(--transition-speed);
}

.nav__wrapper .nav__links .nav__link.signup__btn:hover {
  background: #012345;
  color: white;
}
.mac__screens {
  position: absolute;
  top: 36px;
  left: 276px;
  right: 256px;
}
.mac__screen {
  width: 110%;
  transition: background ease-in-out 250ms;
  //   padding: 1.5rem;
  margin: 0.5rem 0.5rem;
  border-radius: 1px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.185), 0 1px 5px 0 rgba(0, 0, 0, 0.05);
}

#top__right,
#bottom__left {
  width: 100%;
}

.promotion__text {
  text-align: center;
  // transform: scale(1.5);
  margin: 0 auto;
  //   margin-top: -5rem;
  z-index: 1;
  font-size: 1.5rem;
  width: 50%;
  // background: linear-gradient(90deg, #000, #fff, #000);
  background: linear-gradient(90deg, rgba(0, 0, 0, 1), #fff);

  background-repeat: no-repeat;
  overflow: hidden;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  //   animation-fill-mode: forwards;
  //   -webkit-animation-fill-mode: forwards;
  background-position: left;
  h2 {
    font-weight: 600;

    font-size: 3rem;
  }
}

.complaints__info__text {
  text-align: right;
  margin: 0 auto;
  z-index: 1;
  font-size: 1.5rem;
  width: 40%;
  background: linear-gradient(180deg, #000, #000, #fff);

  background-repeat: no-repeat;
  overflow: hidden;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  background-position: top;
  h2 {
    font-weight: 600;

    font-size: 3rem;
  }
}
.theft__info__text {
  text-align: left;
  margin: 0 auto;
  z-index: 1;
  font-size: 1.5rem;
  width: 40%;
  background: linear-gradient(180deg, #000, #000, #fff);

  background-repeat: no-repeat;
  overflow: hidden;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  background-position: top;
  h2 {
    font-weight: 600;

    font-size: 3rem;
  }
}

.map__cluster__info__text {
  z-index: 100;
  text-align: left;
  margin: 0 auto;
  z-index: 1;
  font-size: 1.5rem;
  width: 40%;
  //   background: linear-gradient(180deg, #000, #000, #fff);
  color: white;
  //   background-repeat: no-repeat;
  overflow: hidden;
  //   background-clip: text;
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: rgba(255, 255, 255, 0);
  background-position: top;
  h2 {
    font-weight: 600;

    font-size: 3rem;
  }
}

.there__info__text {
  z-index: 100;
  text-align: center;
  margin: 0 auto;
  z-index: 1;
  width: 100%;
  background: linear-gradient(180deg, #000, #000, #fff);
  color: black;
  background-repeat: no-repeat;
  overflow: hidden;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  background-position: top;
  background-size: 0%;
  h2 {
    font-weight: 600;

    font-size: 4.5rem;
  }
  p {
    font-size: 2rem;
  }
}

.app__informatic__1 {
  text-align: left;
  margin: 0 auto;
  z-index: 1;
  font-size: 1.5rem;
  width: 40%;
  background: linear-gradient(180deg, #000, #000, #fff);

  background-repeat: no-repeat;
  overflow: hidden;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  background-position: top;
  background-size: 0%;
  opacity: 0;
  h2 {
    font-weight: 600;

    font-size: 3rem;
  }
}

#top__left__bottom {
  z-index: 5;
}
#bottom__left {
  z-index: 4;
}

.map__cluster__overlay {
  z-index: 10;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 97.5%;
  background-position-x: -76px;
  background-position-y: 254px;
  background: rgba(0, 0, 0, 0.75);
  background: url("../map-bg.png");
  display: none;

  * {
    z-index: 10;
  }
  .map__cluster__info__text {
    transform: translateY(50%);
    opacity: 0;
    text-align: center;
  }
}

.showcase__wrapper {
  background: transparent;
  display: flex;
  flex-direction: column;
  padding: 10rem 0;
  align-items: center;

  .showcase__header {
    z-index: 1;
    font-weight: 600;

    // background: linear-gradient(90deg, #000, #fff, #000);
    background: linear-gradient(90deg, #fff, rgba(0, 0, 0, 0.1));

    background-repeat: no-repeat;
    overflow: hidden;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    background-position: left;
  }

  .showcase__phone {
    // margin-top: -5%;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgb(0, 0, 0);
    background: transparent;
    // background: linear-gradient(
    //   180deg,
    //   rgba(156, 82, 82, 0.1) 0%,
    //   rgba(0, 0, 0, 1) 100%
    // );
    // background: linear-gradient(
    //   180deg,
    //   rgba(156, 82, 82, 0.1) 0%,
    //   rgba(0, 0, 0, 0.5) 100%
    // );
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: 0 auto;
      left: 0;
      right: 0;
      display: block;
      max-width: 100%;
      z-index: 2;
    }
    // #phone__main,
    // #phone__left__1,
    // #phone__left__2,
    // #phone__right__1,
    // #phone__right__2 {
    //   // opacity: 0;
    //   height: 80%;
    // }

    #phone__main {
      z-index: 5;
      height: 80%;
    }

    #phone__left__1,
    #phone__right__1 {
      height: 75%;
      z-index: 4;
    }

    #phone__left__2,
    #phone__right__2 {
      height: 70%;
      z-index: 3;
    }
  }
}
